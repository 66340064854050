import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'src/store';

export type LoadingState = boolean;

const initialState: LoadingState = false;

export const loadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        startLoading: (state) => {
            // @NOTE: I (Fabian) disabled it because was making the app have a sloppy UX.
            // @NOTE: still I think I feel it can be done in a better way.
            // console.log('loading', true);
            return false;
        },
        endLoading: (state) => {
            // console.log('loading', false);
            return false;
        },
    },
});
export const { startLoading, endLoading } = loadingSlice.actions;

export const selectLoading = (state: RootState) => state.loading;

export default loadingSlice.reducer;
