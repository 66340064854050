export enum UserLanguages {
    en_US = 'en-US',
    en_CA = 'en-CA',
    fr_CA = 'fr-CA',
    es_US = 'es-US',
}

export const languages = [
    { label: 'English', value: UserLanguages.en_US },
    { label: 'French', value: UserLanguages.fr_CA },
    { label: 'Spanish', value: UserLanguages.es_US },
];

export const timeZones = [
    {
        label: 'Hawaii-Aleutian Time UTC-10/UTC-9',
        value: 'US/Aleutian',
    },
    {
        label: 'Alaska Time UTC-9/UTC-8',
        value: 'US/Alaska',
    },
    {
        label: 'Pacific Time UTC-8/UTC -7',
        value: 'US/Pacific',
    },
    {
        label: 'Mountain Time UTC-7/UTC-6',
        value: 'US/Mountain',
    },
    {
        label: 'Central Time UTC-6/UTC-5',
        value: 'US/Central',
    },
    {
        label: 'Eastern Time UTC-5/UTC-4',
        value: 'US/Eastern',
    },
    {
        label: 'Atlantic Time UTC-4/UTC-3',
        value: 'Canada/Atlantic',
    },
    {
        label: 'Newfoundland Time UTC-3:30/UTC-2:30',
        value: 'Canada/Newfoundland',
    },
];
