/**
 * Commented out some as those are outside our area of interest
 * Left it in place as it might be useful in the future
 */

export enum DateFormat {
    MM_DD_YY = 1,
    DD_MM_YY = 2,
    YY_MM_DD = 3,
}

enum Timezone {
    'America/Argentina/ComodRivadavia' = 'America/Argentina/ComodRivadavia',
    'America/Atka' = 'America/Atka',
    'America/Buenos_Aires' = 'America/Buenos_Aires',
    'America/Catamarca' = 'America/Catamarca',
    'America/Coral_Harbour' = 'America/Coral_Harbour',
    'America/Cordoba' = 'America/Cordoba',
    'America/Ensenada' = 'America/Ensenada',
    'America/Fort_Wayne' = 'America/Fort_Wayne',
    'America/Indianapolis' = 'America/Indianapolis',
    'America/Jujuy' = 'America/Jujuy',
    'America/Knox_IN' = 'America/Knox_IN',
    'America/Louisville' = 'America/Louisville',
    'America/Mendoza' = 'America/Mendoza',
    'America/Montreal' = 'America/Montreal',
    'America/Porto_Acre' = 'America/Porto_Acre',
    'America/Rosario' = 'America/Rosario',
    'America/Santa_Isabel' = 'America/Santa_Isabel',
    'America/Shiprock' = 'America/Shiprock',
    'America/Virgin' = 'America/Virgin',
    'Canada/Atlantic' = 'Canada/Atlantic',
    'Canada/Central' = 'Canada/Central',
    'Canada/Eastern' = 'Canada/Eastern',
    'Canada/Mountain' = 'Canada/Mountain',
    'Canada/Newfoundland' = 'Canada/Newfoundland',
    'Canada/Pacific' = 'Canada/Pacific',
    'Canada/Saskatchewan' = 'Canada/Saskatchewan',
    'Canada/Yukon' = 'Canada/Yukon',
    GMT0 = 'GMT0',
    'US/Alaska' = 'US/Alaska',
    'US/Aleutian' = 'US/Aleutian',
    'US/Arizona' = 'US/Arizona',
    'US/Central' = 'US/Central',
    'US/East-Indiana' = 'US/East-Indiana',
    'US/Eastern' = 'US/Eastern',
    'US/Hawaii' = 'US/Hawaii',
    'US/Indiana-Starke' = 'US/Indiana-Starke',
    'US/Michigan' = 'US/Michigan',
    'US/Mountain' = 'US/Mountain',
    'US/Pacific' = 'US/Pacific',
    'US/Samoa' = 'US/Samoa',
    Universal = 'Universal',
    'Etc/Universal' = 'Etc/Universal',
    'Etc/Zulu' = 'Etc/Zulu',
    'Etc/Greenwich' = 'Etc/Greenwich',
    'Etc/GMT-0' = 'Etc/GMT-0',
    'Etc/GMT+0' = 'Etc/GMT+0',
    'Etc/GMT0' = 'Etc/GMT0',
    'Etc/GMT-14' = 'Etc/GMT-14',
    'Etc/GMT-13' = 'Etc/GMT-13',
    'Etc/GMT-12' = 'Etc/GMT-12',
    'Etc/GMT-11' = 'Etc/GMT-11',
    'Etc/GMT-10' = 'Etc/GMT-10',
    'Etc/GMT-9' = 'Etc/GMT-9',
    'Etc/GMT-8' = 'Etc/GMT-8',
    'Etc/GMT-7' = 'Etc/GMT-7',
    'Etc/GMT-6' = 'Etc/GMT-6',
    'Etc/GMT-5' = 'Etc/GMT-5',
    'Etc/GMT-4' = 'Etc/GMT-4',
    'Etc/GMT-3' = 'Etc/GMT-3',
    'Etc/GMT-2' = 'Etc/GMT-2',
    'Etc/GMT-1' = 'Etc/GMT-1',
    'Etc/GMT+1' = 'Etc/GMT+1',
    'Etc/GMT+2' = 'Etc/GMT+2',
    'Etc/GMT+3' = 'Etc/GMT+3',
    'Etc/GMT+4' = 'Etc/GMT+4',
    'Etc/GMT+5' = 'Etc/GMT+5',
    'Etc/GMT+6' = 'Etc/GMT+6',
    'Etc/GMT+7' = 'Etc/GMT+7',
    'Etc/GMT+8' = 'Etc/GMT+8',
    'Etc/GMT+9' = 'Etc/GMT+9',
    'Etc/GMT+10' = 'Etc/GMT+10',
    'Etc/GMT+11' = 'Etc/GMT+11',
    'Etc/GMT+12' = 'Etc/GMT+12',
    WET = 'WET',
    CET = 'CET',
    MET = 'MET',
    EET = 'EET',
    'America/Danmarkshavn' = 'America/Danmarkshavn',
    'America/Scoresbysund' = 'America/Scoresbysund',
    'America/Godthab' = 'America/Godthab',
    'America/Thule' = 'America/Thule',
    'America/New_York' = 'America/New_York',
    'America/Chicago' = 'America/Chicago',
    'America/North_Dakota/Center' = 'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem' = 'America/North_Dakota/New_Salem',
    'America/North_Dakota/Beulah' = 'America/North_Dakota/Beulah',
    'America/Denver' = 'America/Denver',
    'America/Los_Angeles' = 'America/Los_Angeles',
    'America/Juneau' = 'America/Juneau',
    'America/Sitka' = 'America/Sitka',
    'America/Metlakatla' = 'America/Metlakatla',
    'America/Yakutat' = 'America/Yakutat',
    'America/Anchorage' = 'America/Anchorage',
    'America/Nome' = 'America/Nome',
    'America/Adak' = 'America/Adak',
    'Pacific/Honolulu' = 'Pacific/Honolulu',
    'America/Phoenix' = 'America/Phoenix',
    'America/Boise' = 'America/Boise',
    'America/Indiana/Indianapolis' = 'America/Indiana/Indianapolis',
    'America/Indiana/Marengo' = 'America/Indiana/Marengo',
    'America/Indiana/Vincennes' = 'America/Indiana/Vincennes',
    'America/Indiana/Tell_City' = 'America/Indiana/Tell_City',
    'America/Indiana/Petersburg' = 'America/Indiana/Petersburg',
    'America/Indiana/Knox' = 'America/Indiana/Knox',
    'America/Indiana/Winamac' = 'America/Indiana/Winamac',
    'America/Indiana/Vevay' = 'America/Indiana/Vevay',
    'America/Kentucky/Louisville' = 'America/Kentucky/Louisville',
    'America/Kentucky/Monticello' = 'America/Kentucky/Monticello',
    'America/Detroit' = 'America/Detroit',
    'America/Menominee' = 'America/Menominee',
    'America/St_Johns' = 'America/St_Johns',
    'America/Goose_Bay' = 'America/Goose_Bay',
    'America/Halifax' = 'America/Halifax',
    'America/Glace_Bay' = 'America/Glace_Bay',
    'America/Moncton' = 'America/Moncton',
    'America/Blanc-Sablon' = 'America/Blanc-Sablon',
    'America/Toronto' = 'America/Toronto',
    'America/Thunder_Bay' = 'America/Thunder_Bay',
    'America/Nipigon' = 'America/Nipigon',
    'America/Rainy_River' = 'America/Rainy_River',
    'America/Atikokan' = 'America/Atikokan',
    'America/Winnipeg' = 'America/Winnipeg',
    'America/Regina' = 'America/Regina',
    'America/Swift_Current' = 'America/Swift_Current',
    'America/Edmonton' = 'America/Edmonton',
    'America/Vancouver' = 'America/Vancouver',
    'America/Dawson_Creek' = 'America/Dawson_Creek',
    'America/Fort_Nelson' = 'America/Fort_Nelson',
    'America/Creston' = 'America/Creston',
    'America/Pangnirtung' = 'America/Pangnirtung',
    'America/Iqaluit' = 'America/Iqaluit',
    'America/Resolute' = 'America/Resolute',
    'America/Rankin_Inlet' = 'America/Rankin_Inlet',
    'America/Cambridge_Bay' = 'America/Cambridge_Bay',
    'America/Yellowknife' = 'America/Yellowknife',
    'America/Inuvik' = 'America/Inuvik',
    'America/Whitehorse' = 'America/Whitehorse',
}

export default Timezone;
