import { useSelector } from 'react-redux';
import { selectLoading } from 'src/store/slices/loadingSlice';
import Loading from '../layout/Loading';

const CustomLoader = () => {
    const loaderShouldShown = useSelector(selectLoading);

    return loaderShouldShown ? (
        <div className="absolute w-full h-full bg-blackOpacity z-70 flex justify-center items-center">
            <Loading />
        </div>
    ) : null;
};

export default CustomLoader;
