import { SnackbarWithButtons } from 'src/components/common/snackbar';
import { useDispatch } from 'react-redux';
import { hideMissedReferral } from 'src/store/slices/notificationsSlice';
import router from 'next/router';
type TProps = {
    nameUser: string;
    referralId: number;
};

const MyCaseNextStepSnackbar = ({ nameUser, referralId }: TProps) => {
    const dispatch = useDispatch();
    return (
        <SnackbarWithButtons
            header={`Is the consultation with ${nameUser} over?`}
            description="If yes, the funds are moving from locked paid, they display on the balance history and the giver gets this as an update as-well"
            firstBtn={{
                label: 'YES, SEND A FUNDS',
                handler: () => {
                    dispatch(hideMissedReferral(referralId));
                    router.push(
                        `/myCases/${referralId}/update#closed/retainer`,
                    );
                },
            }}
            secondBtn={{
                label: 'NOT INTERESTED',
                handler: () => {
                    dispatch(hideMissedReferral(referralId));
                    router.push(
                        `/myCases/${referralId}/update#closed/retainer`,
                    );
                },
            }}
            thirdBtn={{
                label: 'NOT YET',
                handler: () => dispatch(hideMissedReferral(referralId)),
            }}
            onClose={() => {
                dispatch(hideMissedReferral(referralId));
            }}
        />
    );
};

export default MyCaseNextStepSnackbar;
