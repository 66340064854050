import { CollectionIcon, TableIcon } from '@heroicons/react/solid';
import clsx from 'clsx';

export enum DataViewMode {
    TABLE = 'table',
    COLLECTION = 'collection',
}

const dataViewCollection = [
    {
        name: DataViewMode.TABLE,
        icon: TableIcon,
    },
    {
        name: DataViewMode.COLLECTION,
        icon: CollectionIcon,
    },
];

type TProps = {
    selectedDataView: DataViewMode;
    setSelectedDataView: (value: DataViewMode) => void;
};

const DataViewSelector = ({
    selectedDataView,
    setSelectedDataView,
}: TProps) => {
    return (
        <div className="flex rounded-md border-2 ml-[30px]">
            {dataViewCollection.map((item, key: number) => (
                <div
                    key={key}
                    className={clsx(
                        'w-[44px] h-[34px] flex items-center bg-white justify-center cursor-pointer',
                        {
                            'bg-primaryBtnBg text-white':
                                selectedDataView === item.name,
                            'rounded-l-[4px]': item.name === DataViewMode.TABLE,
                            'rounded-r-[4px]':
                                item.name === DataViewMode.COLLECTION,
                        },
                    )}
                    onClick={() => setSelectedDataView(item.name)}
                >
                    <item.icon className="w-[28px] h-[22px]" />
                </div>
            ))}
        </div>
    );
};

export default DataViewSelector;
