import { createSlice } from '@reduxjs/toolkit';
import { NotificationTypeEnum } from 'src/components/common/snackbar/GeneralSnackbar';
import type { RootState } from 'src/store';

export type TMessage = {
    id?: number;
    header: string;
    description: string;
    type: NotificationTypeEnum;
    redirectTo?: string;
};

interface INewMessage extends TMessage {
    id: number;
}

export type NotificationsState = {
    dialogs: TMessage[];
    messages: TMessage[];
    messagesUpdated: boolean;
    missedReferrals: { [name: string]: any };
    currentCountAvailableReferrals: 0;
};

const initialState: NotificationsState = {
    dialogs: [],
    messages: [],
    messagesUpdated: false,
    missedReferrals: {},
    currentCountAvailableReferrals: 0,
};

const _updateDialogs = (missedReferrals: any) => {
    const updatedDialogs: any = [];
    Object.keys(missedReferrals).forEach((refIndex) => {
        if (missedReferrals[refIndex].show) {
            missedReferrals[refIndex].type = 'nextStep';
            updatedDialogs.push(missedReferrals[refIndex]);
        }
    });

    return updatedDialogs;
};

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        addNewMessages: (state, action) => {
            const remappedNewMessages = action.payload.map(
                (item: INewMessage) => ({
                    ...item,
                    id: ((Math.random() * 10) ^ 6).toFixed(6),
                }),
            );
            state.messages = [...state.messages, ...remappedNewMessages];
            state.messagesUpdated = true;
        },

        updateMessages: (state, action) => {
            state.messages = [...action.payload];
        },

        removeMessage: (state, action) => {
            const filtered = state.messages.filter(
                (item: TMessage) => item?.id !== action.payload,
            );
            state.messages = [...filtered];
        },

        setMessagesUpdated: (state, action) => {
            state.messagesUpdated = action.payload;
        },

        updateMissedReferrals: (state, action) => {
            let newMissedReferrals: { [name: string]: any } = {};
            if (action.payload && action.payload.length) {
                action.payload.forEach((referral: { id: number }) => {
                    if (!state.missedReferrals.hasOwnProperty(referral.id)) {
                        newMissedReferrals = {
                            ...state.missedReferrals,
                            [referral.id]: {
                                showTime: new Date().getTime(),
                                show: true,
                                ...referral,
                            },
                        };
                    } else {
                        newMissedReferrals = {
                            ...state.missedReferrals,
                            [referral.id]: state.missedReferrals[referral.id],
                        };
                    }
                    if (
                        newMissedReferrals[referral.id].showTime + 7200000 <
                        new Date().getTime()
                    ) {
                        newMissedReferrals[referral.id].showTime =
                            new Date().getTime();
                        newMissedReferrals[referral.id].show = true;
                    }
                });
            }

            state.dialogs = _updateDialogs(newMissedReferrals);
            state.missedReferrals = newMissedReferrals;
        },

        hideMissedReferral: (state, action) => {
            const newMissedReferrals = {
                ...state.missedReferrals,
                [action.payload]: {
                    ...state.missedReferrals[action.payload],
                    showTime: new Date().getTime(),
                    show: false,
                },
            };

            state.dialogs = _updateDialogs(newMissedReferrals);
            state.missedReferrals = newMissedReferrals;
        },

        updateCurrentCountAvailableReferrals: (state, action) => {
            state.currentCountAvailableReferrals = action.payload;
        },
    },
});

export const {
    addNewMessages,
    updateMessages,
    removeMessage,
    setMessagesUpdated,

    updateMissedReferrals,
    hideMissedReferral,
    updateCurrentCountAvailableReferrals,
} = notificationsSlice.actions;

export const selectDialogs = (state: RootState) => state.notifications.dialogs;
export const selectMessages = (state: RootState) =>
    state.notifications.messages;
export const selectMessagesUpdated = (state: RootState) =>
    state.notifications.messagesUpdated;

export const selectCurrentCountAvailableReferrals = (state: RootState) =>
    state.notifications.currentCountAvailableReferrals;

export default notificationsSlice.reducer;
