import clsx from 'clsx';
import { IonToast } from '@ionic/react';
import { useEffect, useState } from 'react';
import {
    close,
    alertCircleOutline,
    checkmarkCircleOutline,
} from 'ionicons/icons';

export enum NotificationTypeEnum {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    NORMAL = 'NORMAL',
}

type TProps = {
    header?: string;
    description: string;
    className?: string;
    onClose: () => void;
    type?: NotificationTypeEnum;
};

const GeneralSnackbar = ({
    header,
    description,
    className,
    onClose,
    type = NotificationTypeEnum.NORMAL,
}: TProps) => {
    const [render, setRender] = useState(false);

    let color = '';
    let icon = '';

    switch (type) {
        case NotificationTypeEnum.ERROR: {
            color = 'danger';
            icon = alertCircleOutline;
            break;
        }
        case NotificationTypeEnum.SUCCESS: {
            color = 'success';
            icon = checkmarkCircleOutline;
            break;
        }
        default: {
            color = '';
        }
    }

    useEffect(() => {
        //FYI this is hack for proper render
        if (description) {
            setTimeout(() => {
                setRender(true);
            }, 1000);
        }
    }, [description]);

    const onCloseHandler = () => {
        setRender(false);
        onClose();
    };

    return (
        <IonToast
            isOpen={render}
            onDidDismiss={onCloseHandler}
            position="top"
            color={color}
            icon={icon || undefined}
            header={header}
            message={description}
            duration={7000}
            cssClass={clsx('left-auto right-0 fixed', className)}
            buttons={[
                {
                    side: 'end',
                    icon: close,
                    handler: () => {
                        console.log('Favorite clicked');
                    },
                },
            ]}
        />
    );
};

export default GeneralSnackbar;
