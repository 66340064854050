import { useFetch, usePost } from './index';
import { NEXT_PUBLIC_API_ENDPOINT } from 'src/libs/config';
import { ProfileKycStatus } from 'src/constants/kycLists';
import { KycStatus } from 'src/contexts/kycStatusContext';
import { Country } from 'src/libs/types/country';
import { CreateKycData, LawyerAreaOfLaw } from 'src/libs/types/kyc';
import { pick } from 'lodash';
import { trimObjectValues } from 'src/libs/utils';

export type TKycData = {
    address: string;
    areaOfLaw: LawyerAreaOfLaw[];
    cityId: string;
    country: Country;
    createdAt: string;
    createdBy: string;
    firstName: string;
    id: string;
    lastName: string;
    license: string;
    licensePhotoUrl: string;
    removedAt: null;
    removedBy: null;
    state: string;
    experience: string;
    status: ProfileKycStatus;
    updatedAt: string;
    updatedBy: string;
    userId: string;
    unit: string;
    version: number;
    zip: string;
};

const serializeCreateKycForm = (record: Record<string, any>): CreateKycData => {
    return {
        firstName: record.firstName,
        lastName: record.lastName,
        country: record.country,
        state: record.state,
        address: record.address,
        cityId: record.cityId,
        zip: record.zip,
        license: record.license,
        experience: record.experience,
        areaOfLaw: record.areaOfLaw,
        licensePhotoUrl: record.licensePhotoUrl,
        unit: record.unit,
    };
};

export function useSaveKyc(
    callback?: (value: { error?: any; data?: any }) => void,
): [
    {
        [key: string]: any;
    },
    Function,
    Boolean,
] {
    const [data, postHandler, loading] = usePost(
        `${NEXT_PUBLIC_API_ENDPOINT}/kyc-profile`,
        callback,
    );

    const kycPostHandler = (postData: any) =>
        postHandler(trimObjectValues(serializeCreateKycForm(postData)));

    return [data, kycPostHandler, loading];
}

export function useSaveInvite(
    callback?: (value: { error?: any; data?: any }) => void,
): [
    {
        [key: string]: any;
    },
    Function,
    Boolean,
] {
    // console.log('------invite-------');
    const [dataResult, postHandler, loading] = usePost(
        `${NEXT_PUBLIC_API_ENDPOINT}kyc-profile/invite`,
        callback,
    );

    return [dataResult, postHandler, loading];
}

export const useFetchKycData = () => {
    return useFetch<any>(`${NEXT_PUBLIC_API_ENDPOINT}/kyc-profile/me`, {
        status: KycStatus.NO_DATA,
    });
};
