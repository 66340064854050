import React, { useState, useEffect, useContext } from 'react';
import * as cognito from '../libs/cognito';
import useStorage from 'src/hooks/useStorage';
import { TChildren } from 'src/libs/types/general';

export enum AuthStatus {
    Loading = 'LOADING',
    SignedIn = 'SIGNED IN',
    SignedOut = 'SIGNED OUT',
}

export interface IAuth {
    userSession: cognito.UserSessionData | null;
    attrInfo?: any;
    authStatus: AuthStatus;
    logInWithEmail: (email: string, pass: string) => Promise<void>;
    signUpUserWithEmail: (email: string, pass: string) => Promise<void>;
    signOut: (callback?: () => void) => void;
    signUpSendCodeByPhone: (phone: string) => Promise<void>;
    verifyCodeSentToPhone: (phone: string, code: string) => Promise<void>;
    forgotPasswordSendRetrieveEmail: any;
    forgotPasswordSetNewPassword: any;
    changePassword: any;
    getAttributes?: any;
    isAdmin: () => boolean;
    setAttribute?: any;
    resendCodeToPhone: (phone: string) => Promise<void>;
    logInSendCodeByPhone: (phone: string) => Promise<void>;
}
// @ts-ignore
const defaultState: IAuth = {
    userSession: null,
    authStatus: AuthStatus.Loading,
};

export const AuthContext = React.createContext(defaultState);

export const AuthIsSignedIn: React.FunctionComponent<TChildren> = ({
    children,
}: TChildren) => {
    const { authStatus }: IAuth = useContext(AuthContext);

    return <>{authStatus === AuthStatus.SignedIn ? children : null}</>;
};

export const AuthIsNotSignedIn: React.FunctionComponent<TChildren> = ({
    children,
}: TChildren) => {
    const { authStatus }: IAuth = useContext(AuthContext);

    return <>{authStatus === AuthStatus.SignedOut ? children : null}</>;
};

const AuthProvider: React.FunctionComponent<TChildren> = ({
    children,
}: TChildren) => {
    const [authStatus, setAuthStatus] = useState(AuthStatus.Loading);
    const [userSession, setUserSession] =
        useState<cognito.UserSessionData | null>(null);
    const [attrInfo, setAttrInfo] = useState([]);
    const { removeAllSessionStorageItems } = useStorage();

    useEffect(() => {
        async function getSessionInfo() {
            try {
                const session = await cognito.getSession();
                setUserSession(session);
                setAuthStatus(
                    session ? AuthStatus.SignedIn : AuthStatus.SignedOut,
                );
            } catch (err) {
                console.error(err);
                setAuthStatus(AuthStatus.SignedOut);
            }
        }
        getSessionInfo();
    }, [setAuthStatus, authStatus]);

    if (authStatus === AuthStatus.Loading) {
        return null;
    }

    async function logInWithEmail(email: string, password: string) {
        try {
            await cognito.logInWithEmail(email, password);
            setAuthStatus(AuthStatus.SignedIn);
        } catch (err) {
            setAuthStatus(AuthStatus.SignedOut);
            throw err;
        }
    }

    async function logInSendCodeByPhone(phone: string) {
        try {
            await cognito.logInSendCodeByPhone(phone);
        } catch (err) {
            throw err;
        }
    }

    async function signUpUserWithEmail(email: string, password: string) {
        try {
            await cognito.signUpUserWithEmail(email, password);
            await logInWithEmail(email, password);
        } catch (err) {
            throw err;
        }
    }

    function signOut(callback?: () => void) {
        cognito.signOut();
        setAuthStatus(AuthStatus.SignedOut);
        removeAllSessionStorageItems();
        !!callback && callback();
    }

    /**
     * @TODO: refactor to use permissions and roles, not email
     */
    function isAdmin() {
        return (
            !!userSession && userSession.userData.email === 'admin@athena.legal'
        );
    }

    async function verifyCodeSentToPhone(email: string, code: string) {
        try {
            await cognito.verifyCodeSentToPhone(email, code);
            setAuthStatus(AuthStatus.SignedIn);
        } catch (err) {
            throw err;
        }
    }

    async function getAttributes() {
        try {
            const attr = await cognito.getAttributes();
            return attr;
        } catch (err) {
            throw err;
        }
    }

    async function setAttribute(attr: any) {
        try {
            const res = await cognito.setAttribute(attr);
            return res;
        } catch (err) {
            throw err;
        }
    }

    async function forgotPasswordSendRetrieveEmail(email: string) {
        try {
            await cognito.forgotPasswordSendRetrieveEmail(email);
        } catch (err) {
            throw err;
        }
    }

    async function forgotPasswordSetNewPassword(
        email: string,
        code: string,
        password: string,
    ) {
        try {
            await cognito.forgotPasswordSetNewPassword(email, code, password);
        } catch (err) {
            throw err;
        }
    }

    async function changePassword(oldPassword: string, newPassword: string) {
        try {
            await cognito.changePassword(oldPassword, newPassword);
        } catch (err) {
            throw err;
        }
    }

    async function signUpSendCodeByPhone(phone: string) {
        try {
            await cognito.signUpSendCodeByPhone(phone);
        } catch (err) {
            throw err;
        }
    }

    const state: IAuth = {
        authStatus,
        userSession,
        attrInfo,
        isAdmin,
        signUpUserWithEmail,
        logInWithEmail,
        signOut,
        verifyCodeSentToPhone,
        forgotPasswordSendRetrieveEmail,
        forgotPasswordSetNewPassword,
        changePassword,
        getAttributes,
        setAttribute,
        resendCodeToPhone: cognito.resendCodeToPhone,
        signUpSendCodeByPhone,
        logInSendCodeByPhone,
    };

    return (
        <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
    );
};

export default AuthProvider;
