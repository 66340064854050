import { useContext, useState } from 'react';
import { NEXT_PUBLIC_API_ENDPOINT } from 'src/libs/config';
import { AuthContext } from 'src/contexts/authContext';
import useServerErrorHandler from 'src/hooks/useServerErrorHandler';
import { Http, HttpOptions } from '@capacitor-community/http';

const normalizeParams = (params: Record<string, any>) => {
    const normalizedParams: Record<string, any> = {};
    if (typeof params === 'object' && !Array.isArray(params)) {
        for (let key in params) {
            normalizedParams[key] = params[key].toString();
        }
    }

    return normalizedParams;
};

const isHttpErrorStatusCode = (res: { status: number }) => {
    if (res.status >= 400) {
        return true;
    }
};
const handleErrorResponse = (res: {
    status: number;
    data: Record<string, any>;
}) => {
    if (isHttpErrorStatusCode(res)) {
        throw res;
    }
    return res;
};

const generateOptions = (url: string, accessToken?: string) => {
    const options: HttpOptions = {
        url: url,
        headers: {
            Authorization: `Bearer ${accessToken}` || '',
            origin: NEXT_PUBLIC_API_ENDPOINT || '',
            'content-type': 'application/json;charset=utf-8',
        },
    };

    return options;
};

const useRequest = () => {
    const { userSession } = useContext(AuthContext);

    const request = {
        get: async (url: any, query: any) => {
            const options = generateOptions(url, userSession?.accessToken);
            options.params = normalizeParams(query);

            return Http.get(options).then(handleErrorResponse);
        },
        post: async (url: any, body: any) => {
            const options = generateOptions(url, userSession?.accessToken);
            options.data = body;

            return Http.post(options).then(handleErrorResponse);
        },
        put: async (url: any, body: any) => {
            const options = generateOptions(url, userSession?.accessToken);
            options.data = body;

            return Http.put(options).then(handleErrorResponse);
        },
        patch: async (url: any, body: any) => {
            const options = generateOptions(url, userSession?.accessToken);
            options.data = body;

            return await Http.patch(options).then(handleErrorResponse);
        },
        delete: async (url: any, query: any) => {
            const options = generateOptions(url, userSession?.accessToken);
            options.params = normalizeParams(query);

            return await Http.del(options).then(handleErrorResponse);
        },
    };
    return { request, tokenReceived: !!userSession?.accessToken };
};

export type TObject = { [key: string]: any };
interface IResponse {
    data: TObject;
}

export function useFetch<Data = any>(
    url: string,
    defaultData?: Data,
    // @TODO: refactor to make it false by default.
    showSnackBarOnError: boolean = true,
): [{ data: Data | undefined; error?: any }, Function, boolean, boolean] {
    //TODO Need to add data: any for resolve typescript errors

    const { request, tokenReceived } = useRequest();
    const [loading, setLoading] = useState(true);
    const [setGlobalError] = useServerErrorHandler();

    const [response, setResponse] = useState({
        data: defaultData,
        error: undefined,
    });

    const load = (query?: TObject) => {
        setLoading(true);
        request
            .get(url, query)
            .then((response: any) => {
                if (!response.data.error) {
                    setResponse({
                        data: response.data,
                        error: undefined,
                    });
                    setLoading(false);
                } else {
                    errorHandler(response);
                }
            })
            .catch((error: any) => {
                errorHandler(error);
            });
    };

    const errorHandler = (error: any) => {
        setResponse({
            data: defaultData,
            error: error.response,
        });
        setLoading(false);
        if (showSnackBarOnError) {
            setGlobalError(error);
        }
    };

    // @TODO: We should not tied this function to something specific for auth like token received
    // refactor this to not use tokenReceived
    return [response, load, loading, tokenReceived];
}

export function usePost<ResponseType = any>(
    url: string,
    callback?: (value: any) => void,
): [ResponseType, Function, boolean, any] {
    const [handleServerError] = useServerErrorHandler();
    const { request } = useRequest();
    const [data, setData] = useState<ResponseType>({} as ResponseType);
    const [error, setError] = useState<any>(undefined);
    const [loading, setLoading] = useState(false);

    const post = (body: any) => {
        setLoading(true);

        request
            .post(url, body)
            .then((response: any) => {
                setData(response.data);
                setLoading(false);
                callback && callback({ data: response.data });
            })
            .catch((error: any) => {
                setLoading(false);
                setError(error);
                callback && callback({ error: error.response });
                handleServerError(error);
            });
    };

    return [data, post, loading, error];
}

export type PatchCallback = (value: any) => void;

export function usePatch<DataType>(
    url: string,
    callback?: (value: any) => void,
): [{ [key: string]: any }, (data: DataType) => void, boolean, any] {
    const [handleServerError] = useServerErrorHandler();
    const { request } = useRequest();
    const [data, setData] = useState({});
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState(false);

    const patch = (body: DataType) => {
        setLoading(true);

        request
            .patch(url, body)
            .then((response: IResponse) => {
                const data = response.data || { success: true };
                setData(data);
                setLoading(false);
                callback && callback({ data });
            })
            .catch((error: any) => {
                console.error(error);
                setLoading(false);
                setError(error);
                callback && callback({ error: error.response });
                handleServerError(error);
            });
    };

    return [data, patch, loading, error?.response];
}

export function useDelete(
    url: string,
    callback?: (value: any) => void,
): [{ [key: string]: any }, Function, Boolean] {
    const [handleServerError] = useServerErrorHandler();
    const { request } = useRequest();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    const deleteRef = (query: any) => {
        setLoading(true);

        request
            .delete(url, query)
            .then((response: IResponse) => {
                setData(response.data);
                setLoading(false);
                callback && callback({ data: response.data });
            })
            .catch((error: any) => {
                console.error(error);
                setLoading(false);
                callback && callback({ error: error.response });
                handleServerError(error);
            });
    };

    return [data, deleteRef, loading];
}

export function usePut(
    url: string,
    callback?: (val: { error?: any; data?: any }) => void,
): [{ [key: string]: any }, Function, Boolean] {
    const [handleServerError] = useServerErrorHandler();
    const { request } = useRequest();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    const post = (body: any) => {
        setLoading(true);

        request
            .put(url, body)
            .then((response: IResponse) => {
                setData(response.data);
                setLoading(false);
                callback && callback({ data: response.data });
            })
            .catch((error: any) => {
                console.error(error);
                setLoading(false);
                callback && callback({ error: error.response });
                handleServerError(error);
            });
    };

    return [data, post, loading];
}
