import * as isEmail from 'isemail';
import { DateTime } from 'luxon';
import { ReferralTimeStatusEnum } from 'src/constants';
import { countryCodes } from './constants';
import { Country } from './types/country';
import { isToday, isYesterday, differenceInMinutes } from 'date-fns';
import { groupBy } from 'lodash';
import { DataViewMode } from 'src/components/pages/general/DataViewSelector';

export type TFormData = { [key: string]: any };
export type ConvertingDataTypes = string | Array<any> | object | null | boolean;

export const isDev =
    process.env.NEXT_PUBLIC_API_ENDPOINT === 'https://api.dev.athena.legal';

export const classNames = (...classes: any) => {
    return classes.filter(Boolean).join(' ');
};

export const isEmailLoginDataValid = (
    email: string,
    passwordErrors: string[],
) => isEmail.validate(email) && !passwordErrors.length;
export const hasLowerCase = (str: string) => str.toLowerCase() !== str;
export const hasUppercase = (str: string) => str.toUpperCase() !== str;
export const hasNumbers = (str: string) => /\d/.test(str);
export const hasSymbols = (str: string) => /\W/.test(str);
export const getCountryCodeNumber = (countryCodeId: string) =>
    countryCodes.find((c) => c.value === countryCodeId)?.code;

export const serializeErrors = (errors: Array<string>) =>
    errors
        .filter((err) => err)
        .slice(0, 2)
        .join('. ');

export const trimObjectValues = (object: { [key: string]: any }) => {
    for (let e in object) {
        if (typeof object[e] === 'string') {
            object[e] = object[e].trim();
        }
    }

    return object;
};

export const convertDataToString = (value: ConvertingDataTypes): string => {
    let convertedValue = '';
    if (value === null) {
        convertedValue = 'null';
    } else if (typeof value === 'boolean') {
        convertedValue = value ? 'true' : 'false';
    } else if (typeof value === 'string') {
        convertedValue = value;
    } else if (typeof value === 'object') {
        convertedValue = JSON.stringify(value);
    }
    return convertedValue;
};

export const removePropsFromObj = (
    object: TFormData,
    props: Array<string | number> | string,
) => {
    let newObject = object;
    if (typeof props === 'string') {
        delete newObject[props];
    } else if (Array.isArray(props)) {
        for (let i of props) {
            delete newObject[i];
        }
    }
    return newObject;
};

export const extractPropsFromObj = (
    object: TFormData,
    props: Array<string | number> | string,
) => {
    const newObject: TFormData = {};
    if (typeof props === 'string') {
        newObject[props] = object[props];
    } else if (Array.isArray(props)) {
        for (let i of props) {
            newObject[i] = object[i];
        }
    }
    return newObject;
};

export const convertTime = (value: string, countryCode?: Country | string) => {
    let format = "MM'-'dd'-'yyyy 'at' hh:mm a";
    switch (countryCode) {
        case Country.UnitedStates:
            format = "MM'-'dd'-'yyyy 'at' hh:mm a";
            break;
        case Country.Canada:
            format = "yyyy'-'MM'-'dd 'at' hh:mm a";
            break;
    }
    return DateTime.fromISO(value).toFormat(format);
};

export const convertReferralTimeLeft = (value: number) => {
    let status = ReferralTimeStatusEnum.ACTUAL;
    const hours = Math.trunc(value / 60);
    const minutes = value - hours * 60;

    if (value < 12 * 60 && value >= 1 * 60) {
        status = ReferralTimeStatusEnum.RUN_OUT;
    } else if (value < 1 * 60) {
        status = ReferralTimeStatusEnum.EXPIRED;
    }

    return {
        time:
            value || value === 0
                ? `${hours ? hours + 'h' : ''} ${minutes}m`
                : '',
        status: value || value === 0 ? status : '',
    };
};

export const convertAcceptedTimeLeft = (acceptedAt: string) => {
    let status = ReferralTimeStatusEnum.ACTUAL;
    const acceptedAtMin = new Date(acceptedAt).getTime() / 1000 / 60;

    const hours = Math.trunc(acceptedAtMin / 60);
    const minutes = acceptedAtMin - hours * 60;

    const currentTimeMin = new Date().getTime() / 1000 / 60;
    const value = currentTimeMin - acceptedAtMin;
    if (value > 12 * 60 && value <= 24 * 60) {
        status = ReferralTimeStatusEnum.RUN_OUT;
    } else if (value > 24 * 60) {
        status = ReferralTimeStatusEnum.EXPIRED;
    }

    return {
        time:
            value || value === 0
                ? `${hours ? hours + 'h' : ''} ${minutes}m`
                : '',
        status: value || value === 0 ? status : '',
    };
};

export const normalizePhoneNumber = (str: string) => {
    return str.replaceAll(/\(|-|_|\)/g, '');
};

interface withCreatedAt {
    createdAt: Date;
}

/**
 *
 * @param list
 * @returns "{now: [] , yesterday: [], today: []}"
 */
export const groupByCreationDate = <T extends withCreatedAt>(
    list: T[],
): Record<string, T[]> => {
    const groups: Record<string, (el: T) => boolean> = {
        Now: (el: T): boolean =>
            differenceInMinutes(new Date(), el.createdAt) < 60,
        Today: (el: T): boolean => isToday(el.createdAt),
        Yesterday: (el: T): boolean => isYesterday(el.createdAt),
        Previous: () => true,
    };
    const groupByFn = (el: T) => {
        for (let group in groups) {
            if (groups[group](el)) {
                return group;
            }
        }
    };

    return groupBy<T>(list, groupByFn);
};
/**
 * converts the deep/link to a link that is used in the frontend implementation.
 * Keep in mind deepLinks doesn't have to mach the frontend or backend endpoints,
 * the idea is that they keep usable even if BE or FE totally changes.
 * @param deepLink example /link/kyc-profiles/3e4fb0a-c0e5-43d7-b3bb-87ba42dbfe8d
 */
export const getRouteFromDeepLink = (deepLink = ''): string => {
    const [, root, resource, id] = deepLink.split('/');
    switch (resource) {
        case 'kyc-profiles':
            return '/kyc/';
        case 'referrals':
            return '/myReferrals/' + id;
        case 'get-referral':
            return `/getReferrals/?search=${id}&view=${DataViewMode.COLLECTION}`;
        default:
            return '/';
    }
};

export const getClassMoneyColor = (value?: string | number) => {
    const parsed = typeof value === 'string' ? parseFloat(value) : value || 0;
    const normalized = !isNaN(parsed) ? parsed : 0;
    if (normalized > 0) {
        return 'text-green';
    } else if (normalized < 0) {
        return 'text-redErr';
    }
    return 'text-grayText';
};

export const formatMoneyValue = (value?: string | number) => {
    const parsed = typeof value === 'string' ? parseFloat(value) : value || 0;
    const normalized = !isNaN(parsed) ? parsed : 0;
    return Number(normalized).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        currency: 'USD',
        currencyDisplay: 'symbol',
        style: 'currency',
    });
};

export const isEqualObj = (obj1: any, obj2: any) => {
    let isEqual = true;
    for (let e in obj1) {
        if (isEqual) {
            isEqual = obj2[e] === obj1[e] || obj1[e] === undefined;
        }
    }

    for (let e in obj2) {
        if (isEqual) {
            isEqual = obj2[e] === obj1[e] || obj2[e] === undefined;
        }
    }

    return isEqual;
};

export const getQueryString = (query: Record<string, any>) => {
    let str = '?';

    for (let e in query) {
        str = str + `${e}=${query[e]}&`;
    }

    return str.slice(0, -1);
};
