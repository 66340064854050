import { UserLanguages } from 'src/constants/accountsLists';
import { ProfileKycStatus } from 'src/constants/kycLists';
import { NEXT_PUBLIC_API_ENDPOINT } from 'src/libs/config';
import { Country } from 'src/libs/types/country';
import { LawyerAreaOfLaw } from 'src/libs/types/kyc';
import Timezone, { DateFormat } from 'src/libs/types/timezones';
import { useFetch, usePatch, usePost } from './index';

export type TAccount = {
    id: string;
    userId: string;
    firstName: string;
    lastName: string;
    country: Country;
    state: string;
    address: string;
    cityId: string;
    experience: string;
    zip: string;
    license: string;
    areaOfLaw: LawyerAreaOfLaw[];
    licensePhotoUrl: string;
    status: ProfileKycStatus;
    createdBy: string;
    updatedBy: string;
    removedBy: string;
    createdAt: Date;
    updatedAt: Date | null;
    removedAt: Date | null;
    version?: number;
    email?: string;
    phone?: string;
    stripeId?: string;
    avatar?: string;
    about: string;
    jobInformation?: string;
    authPhoneNumber?: string;
    authEmail?: string;
    isAutomaticTimeZone: boolean;
    language: UserLanguages;
    timeZone: Timezone;
};

export type TUpdateAccount = {
    firstName?: string;
    lastName?: string;
    state?: string;
    address?: string;
    cityId?: string;
    zip?: string;
    about?: string;
    timeZone?: Timezone;
    language?: UserLanguages;
    isAutomaticTimeZone?: boolean;
    dateFormat?: DateFormat;
    areaOfLaw?: LawyerAreaOfLaw;
    avatar?: string;
    jobInformation?: string;
    email?: string;
    phone?: string;
};

export const useFetchAccountData = (userId?: string) => {
    return useFetch<TAccount>(
        `${NEXT_PUBLIC_API_ENDPOINT}/users/${userId || 'me'}`,
        {} as unknown as TAccount,
    );
};

const serializeUpdateAccountData = (data: any): TUpdateAccount => ({
    firstName: data.firstName,
    lastName: data.lastName,
    state: data.state,
    address: data.address,
    cityId: data.cityId,
    zip: data.zip,
    about: data.about,
    timeZone: data.timeZone,
    language: data.language,
    isAutomaticTimeZone: data.isAutomaticTimeZone,
    dateFormat: data.dateFormat,
    areaOfLaw: data.areaOfLaw,
    avatar: data.avatar,
    jobInformation: data.jobInformation,
    email: data.email,
    phone: data.phone,
});

export const usePatchAccountData = (
    userId?: string,
    callback?: ({ error }: { error?: Record<string, any> }) => void,
): [{ [key: string]: any }, (data: TUpdateAccount) => void, boolean, any] => {
    const wrappedCallback = (resp: { data: any; error: any }) => {
        callback && callback(resp);
    };

    const [data, handler, loading, error] = usePatch<TUpdateAccount>(
        `${NEXT_PUBLIC_API_ENDPOINT}/users/${userId || 'me'}`,
        wrappedCallback,
    );

    return [
        data,
        (data: TUpdateAccount) => handler(serializeUpdateAccountData(data)),
        loading,
        error,
    ];
};

export type VerifyEmailPayload = {
    email: string;
    token: string;
};

export const useVerifyEmail = (
    callback?: ({ error }: { error?: Record<string, any> }) => void,
) => {
    return usePatch<VerifyEmailPayload>(
        `${NEXT_PUBLIC_API_ENDPOINT}/auth/check-verification-email`,
        callback,
    );
};

export const useSignRegistrationDoc = (
    referralId: string | null,
    callback?: (val: { error?: any; data?: any }) => void,
): [Record<string, any>, Function, Boolean] => {
    const [data, postHandler, loading] = usePost(
        `${NEXT_PUBLIC_API_ENDPOINT}/users/sign`,
        callback,
    );

    return [data, postHandler, loading];
};

export const useAcceptSignedRegistrationDoc = (
    code: string | string[] | undefined,
    callback?: (val: { error?: any; data?: any }) => void,
): [Record<string, any>, Function, Boolean] => {
    const [data, postHandler, loading] = usePost(
        `${NEXT_PUBLIC_API_ENDPOINT}/users/sign/confirm`,
        callback,
    );

    return [data, postHandler, loading];
};
