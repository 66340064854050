import React, { useState, useEffect, useContext, ReactNode } from 'react';
import { TKycData, useFetchKycData } from '../services/useKyc';
import useStorage from 'src/hooks/useStorage';
import Router from 'next/router';
import isWindow from 'is-window';
import { ROOT_PAGE_URL } from 'src/constants/links';
import { TChildren } from 'src/libs/types/general';

const SHOULD_HIDE_DIALOG = 'shouldHideDialog';

export enum KycStatus {
    LOADING = 'LOADING',
    NO_DATA = 'NO_DATA',
    UNVERIFIED = 'UNVERIFIED',
    ON_VERIFICATION = 'ON_VERIFICATION',
    VERIFIED = 'VERIFIED',
    FAILED_VERIFICATION = 'FAILED_VERIFICATION',
}

type TDefaultState = {
    kycData: TKycData | { [key: string]: any };
    shouldHideFirstTimeDialog: boolean;
    shouldShowDialog: boolean;
    updateKycData?: (data: TKycData | { [key: string]: any }) => void;
    hideFirstTimeDialog?: () => void;
    showDialog?: () => void;
    hideDialog?: () => void;
};

const defaultState: TDefaultState = {
    kycData: {},
    shouldHideFirstTimeDialog: false,
    shouldShowDialog: false,
};

export const KycStatusContext = React.createContext(defaultState);

export const KycFormCanBeShow = ({ children }: { children: ReactNode }) => {
    const { kycData }: TDefaultState = useContext(KycStatusContext);

    if ([KycStatus.LOADING, KycStatus.VERIFIED].includes(kycData.status)) {
        isWindow() && window.history.length > 1
            ? Router.back()
            : Router.push(ROOT_PAGE_URL);

        return null;
    }

    return <>{children}</>;
};

let interval: NodeJS.Timer | null = null;

const KycStatusProvider: React.FunctionComponent<TChildren> = ({
    children,
}: TChildren) => {
    const [{ data, error }, loadKycData, loading, tokenReceived] =
        useFetchKycData();
    const { setSessionStorageItem, getSessionStorageItem } = useStorage();

    const [shouldHideFirstTimeDialog, setShouldHideFirstTimeDialog] =
        useState(false);
    const [shouldShowDialog, setShouldShowDialog] = useState(false);
    const [kycData, setKycData] = useState<TDefaultState['kycData']>({});

    useEffect(() => {
        if (tokenReceived) {
            loadKycData();
            setShouldHideFirstTimeDialog(
                getSessionStorageItem(SHOULD_HIDE_DIALOG) === 'true',
            );
        }

        return () => {
            if (interval) {
                clearInterval(interval);
                interval = null;
            }
        };
    }, [tokenReceived]);

    useEffect(() => {
        if (loading && data) {
            setKycData({ ...data, status: KycStatus.LOADING });
        } else {
            setKycData(data);
        }
        clearKycInterval(data, error);
        !error && createKycInterval(data);
    }, [data, loading, error]);

    const hideFirstTimeDialog = () => {
        setSessionStorageItem(SHOULD_HIDE_DIALOG, true);
        setShouldHideFirstTimeDialog(true);
    };

    const updateKycData = (data: TDefaultState['kycData']) => {
        !!data && setKycData(data);
        clearKycInterval(data);
        createKycInterval(data);
    };

    const createKycInterval = (data: TDefaultState['kycData']) => {
        if (
            ![
                KycStatus.VERIFIED,
                KycStatus.FAILED_VERIFICATION,
                KycStatus.NO_DATA,
            ].includes(data.status) &&
            !interval &&
            tokenReceived
        ) {
            loadKycData();
            interval = setInterval(() => {
                loadKycData();
            }, 15 * 1000);
        }
    };

    const clearKycInterval = (data: TDefaultState['kycData'], error?: any) => {
        if (
            ([
                KycStatus.VERIFIED,
                KycStatus.FAILED_VERIFICATION,
                KycStatus.NO_DATA,
            ].includes(data.status) ||
                error) &&
            interval
        ) {
            clearInterval(interval);
            interval = null;
        }
    };

    const state = {
        kycData: {
            ...kycData,
            status: kycData?.status || KycStatus.LOADING,
        },
        shouldShowDialog,
        shouldHideFirstTimeDialog,
        updateKycData,
        hideDialog: () => setShouldShowDialog(false),
        showDialog: () => setShouldShowDialog(true),
        hideFirstTimeDialog,
    };

    return (
        <KycStatusContext.Provider value={state}>
            {children}
        </KycStatusContext.Provider>
    );
};

export default KycStatusProvider;
