import { CountryCode } from 'libphonenumber-js';

type CountryCodeOption = {
    value: CountryCode;
    label: string;
    code: string;
};
const isDev =
    process.env.NEXT_PUBLIC_API_ENDPOINT === 'https://api.dev.athena.legal';

const devCodes: CountryCodeOption[] = isDev
    ? [
          {
              value: 'CO',
              label: 'CO (+57)',
              code: '+57',
          },
          {
              value: 'UA',
              label: 'UA (+380)',
              code: '+380',
          },
      ]
    : [];

export const countryCodes: CountryCodeOption[] = [
    {
        value: 'US',
        label: 'US (+1)',
        code: '+1',
    },
    {
        value: 'CA',
        label: 'CA (+1)',
        code: '+1',
    },
    ...devCodes,
];
