import clsx from 'clsx';
import Router from 'next/router';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GeneralSnackbar } from 'src/components/common/snackbar';
import MyCaseNextStepSnackbar from 'src/components/pages/myCases/MyCaseNextStepSnackbar';
import MyCaseReminderSnackbar from 'src/components/pages/myCases/MyCaseReminderSnackbar';
import { WEB_BUILD_ENV } from 'src/libs/config';

import {
    selectDialogs,
    selectMessages,
    selectMessagesUpdated,
    updateMessages,
    removeMessage,
    setMessagesUpdated,
} from 'src/store/slices/notificationsSlice';

const Notifications = () => {
    const dispatch = useDispatch();

    const dialogs = useSelector(selectDialogs);
    const messages = useSelector(selectMessages);
    const messagesUpdated = useSelector(selectMessagesUpdated);

    // Probably this logic should moved to the GeneralSnackbar!!!
    useEffect(() => {
        let interval: NodeJS.Timer | null = null;
        if (messagesUpdated) {
            interval = setInterval(() => {
                const [first, ...last] = messages;
                dispatch(updateMessages(last));
                if (!!first?.redirectTo) {
                    Router.push(first.redirectTo);
                }

                if (!last.length) {
                    !!interval && clearInterval(interval);
                    setMessagesUpdated(false);
                }
            }, 3000);
        }

        return () => {
            !!interval && clearInterval(interval);
        };
    }, [messages, messagesUpdated]);

    return (
        <div className="fixed top-0 right-0" style={{ zIndex: 100 }}>
            {dialogs.map((item: any, key: number) => {
                return (
                    <>
                        {item.type === 'nextStep' && (
                            <MyCaseNextStepSnackbar
                                key={key}
                                nameUser={`${item.clientFirstName} ${item.clientLastName}`}
                                referralId={item.id}
                            />
                        )}
                        {/* {item.type === 'nextStep' && (
                            <MyCaseReminderSnackbar onClose={() => {}} />
                        )} */}
                    </>
                );
            })}
            {messages.length ? (
                <GeneralSnackbar
                    header={messages[0]?.header}
                    description={messages[0]?.description}
                    type={messages[0]?.type}
                    className={clsx(WEB_BUILD_ENV ? 'w-[24rem]' : '')}
                    onClose={() => dispatch(removeMessage(messages[0].id))}
                />
            ) : null}
        </div>
    );
};

export default Notifications;
