import { convertDataToString, ConvertingDataTypes } from 'src/libs/utils';

enum StorageType {
    SESSION = 'session',
    LOCAL = 'local',
}

type GetItemType = (key: string) => string;
type SetItemType = (key: string, value: ConvertingDataTypes) => boolean;
type RemoveItemType = (key: string) => void;
type RemoveAllItemsType = () => void;

type UseStorageReturnValue = {
    setLocalStorageItem: SetItemType;
    getLocalStorageItem: GetItemType;
    removeLocalStorageItem: RemoveItemType;
    setSessionStorageItem: SetItemType;
    getSessionStorageItem: GetItemType;
    removeSessionStorageItem: RemoveItemType;
    removeAllSessionStorageItems: RemoveAllItemsType;
    removeAllLocalStorageItems: RemoveAllItemsType;
};

const useStorage = (): UseStorageReturnValue => {
    const storageType = (
        type?: StorageType,
    ): 'localStorage' | 'sessionStorage' => `${type ?? 'session'}Storage`;

    const isBrowser: boolean = ((): boolean => typeof window !== 'undefined')();

    const getItem = (key: string, type?: StorageType): string => {
        return isBrowser ? window[storageType(type)][key] : '';
    };

    const setItem = (
        key: string,
        value: ConvertingDataTypes,
        type?: StorageType,
    ): boolean => {
        if (isBrowser) {
            const preparedValue = convertDataToString(value);
            window[storageType(type)].setItem(key, preparedValue);
            return true;
        }

        return false;
    };

    const clear = (type?: StorageType) => {
        if (isBrowser) {
            window[storageType(type)].clear();
            return true;
        }

        return false;
    };

    const removeItem = (key: string, type?: StorageType): void => {
        window[storageType(type)].removeItem(key);
    };

    const setLocalStorageItem = (key: string, value: ConvertingDataTypes) => {
        return setItem(key, value, StorageType.LOCAL);
    };

    const getLocalStorageItem = (key: string) => {
        return getItem(key, StorageType.LOCAL);
    };

    const removeLocalStorageItem = (key: string) => {
        return removeItem(key, StorageType.LOCAL);
    };

    const removeAllLocalStorageItems = () => {
        return clear(StorageType.LOCAL);
    };

    const setSessionStorageItem = (key: string, value: ConvertingDataTypes) => {
        return setItem(key, value, StorageType.SESSION);
    };

    const getSessionStorageItem = (key: string) => {
        return getItem(key, StorageType.SESSION);
    };

    const removeSessionStorageItem = (key: string) => {
        return removeItem(key, StorageType.SESSION);
    };

    const removeAllSessionStorageItems = () => {
        return clear(StorageType.SESSION);
    };

    return {
        setLocalStorageItem,
        getLocalStorageItem,
        removeLocalStorageItem,
        removeAllLocalStorageItems,
        setSessionStorageItem,
        getSessionStorageItem,
        removeSessionStorageItem,
        removeAllSessionStorageItems,
    };
};

export default useStorage;
