import 'assets/styles/globals.css';
import 'assets/styles/variables.css';
import { Provider } from 'react-redux';
import { store } from 'src/store';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import type { AppProps } from 'next/app';
import AuthProvider from 'src/contexts/authContext';
import KycStatusProvider from 'src/contexts/kycStatusContext';
import GeneralRouter from 'src/components/pages/GeneralRouter';
import AccountProvider from 'src/contexts/accountContext';

import Head from 'next/head';
import Loader from 'src/components/general/Loader';
import Notifications from 'src/components/general/Notifications';
import { TrackingHeadScript } from '@phntms/next-gtm';
import AppUrlListener from 'src/components/general/AppUrlListener';

import { IonPage, setupIonicReact } from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
// import '@ionic/react/css/structure.css';
// import '@ionic/react/css/typography.css';

// /* Optional CSS utils that can be commented out */
// import '@ionic/react/css/padding.css';
// import '@ionic/react/css/float-elements.css';
// import '@ionic/react/css/text-alignment.css';
// import '@ionic/react/css/text-transformation.css';
// import '@ionic/react/css/flex-utils.css';
// import '@ionic/react/css/display.css';

const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID || '';

setupIonicReact();

function MyApp({ Component, pageProps }: AppProps) {
    const [history, setHistory] = useState<string[]>([]);
    const { asPath } = useRouter();

    useEffect(() => {
        if (history[history.length - 1] !== asPath) {
            setHistory((prevState) => [...prevState, asPath]);
        }
    }, [asPath]);

    return (
        <>
            <TrackingHeadScript id={GA_TRACKING_ID} />
            <Provider store={store}>
                <Head>
                    <title>Athena Legal</title>
                </Head>
                <Loader />
                <Notifications />
                <AppUrlListener />

                <AuthProvider>
                    <KycStatusProvider>
                        <AccountProvider>
                            <GeneralRouter>
                                <Component history={history} {...pageProps} />
                            </GeneralRouter>
                        </AccountProvider>
                    </KycStatusProvider>
                </AuthProvider>
            </Provider>
        </>
    );
}

export default MyApp;

MyApp.getStaticProps = async ({ Component, ctx }: any) => {
    let pageProps = {};

    if (Component.getStaticProps) {
        pageProps = await Component.getStaticProps(ctx);
    }

    return { pageProps };
};
