import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';

const FIRST_LEVEL_DOMAIN = '.legal';

const AppUrlListener: React.FC<any> = () => {
    const router = useRouter();
    useEffect(() => {
        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            // Example url: https://beerswift.app/tabs/tab2
            // slug = /tabs/tab2
            const slug = event.url.split(FIRST_LEVEL_DOMAIN).pop();
            if (slug) {
                router.push(slug);
            }
            // If no match, do nothing - let regular routing
            // logic take over
        });
    }, []);

    return null;
};

export default AppUrlListener;
