import clsx from 'clsx';
import { CheckCircleIcon } from '@heroicons/react/outline';
import XIcon from '@heroicons/react/solid/XIcon';

type TButton = {
    label: string;
    handler: () => void;
};

type TProps = {
    className?: string;
    header: string;
    description: string;
    firstBtn?: TButton;
    secondBtn?: TButton;
    thirdBtn?: TButton;
    onClose: () => void;
};

const SnackbarWithButtons = ({
    className,
    header,
    description,
    firstBtn,
    secondBtn,
    thirdBtn,
    onClose,
}: TProps) => {
    return (
        <div
            className={clsx(
                `flex p-[1.5rem] bg-white rounded-2xl shadow-xl m-5 absolute top-0 right-0 z-50 min-w-[320px] max-w-[700px]`,
                className,
            )}
        >
            <div className="left min-w-6 mr-4">
                <CheckCircleIcon className="w-6 cursor-pointer" />
            </div>
            <div className="middle">
                <div
                    className={`header font-semibold font-mont mb-2.5 text-base`}
                >
                    {header}
                </div>
                <div className={`body font-normal font-mont text-base`}>
                    {description}
                </div>
                <div
                    className={`flex flex-col md:flex-row footer font-mont font-semibold`}
                >
                    {firstBtn && (
                        <div
                            className="mt-3 md:mr-[3rem] whitespace-nowrap cursor-pointer"
                            onClick={firstBtn.handler}
                        >
                            {firstBtn.label}
                        </div>
                    )}
                    {secondBtn && (
                        <div
                            className="mt-3 md:mr-[3rem] whitespace-nowrap cursor-pointer"
                            onClick={secondBtn.handler}
                        >
                            {secondBtn.label}
                        </div>
                    )}
                    {thirdBtn && (
                        <div
                            className="mt-3 md:mr-[3rem] whitespace-nowrap cursor-pointer"
                            onClick={thirdBtn.handler}
                        >
                            {thirdBtn.label}
                        </div>
                    )}
                </div>
            </div>
            <div className="right min-w-6 ml-auto">
                <XIcon className="w-6 cursor-pointer" onClick={onClose} />
            </div>
        </div>
    );
};

export default SnackbarWithButtons;
