import { navigation, userNavigation } from './navigation';

export const COUNTDOWN_VALUE = 30 * 1000;

export enum CountriesEnum {
    CA = 'CA',
    US = 'US',
}

export enum CurrenciesEnum {
    CAD = 'CAD',
    USD = 'USD',
}

export enum StateReferrals {
    pending = 'pending',
    taken = 'taken',
    all = 'all',
    active = 'active',
    closed = 'closed',
}
export enum ReferralTimeStatusEnum {
    ACTUAL = 'actual',
    RUN_OUT = 'run_out',
    EXPIRED = 'expired',
}

export enum ReferralStatus {
    CREATED = 'CREATED',
    ACCEPTED = 'ACCEPTED',
    WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
    CONSULTATION_SCHEDULED = 'CONSULTATION_SCHEDULED',
    NO_INTERESTED = 'NO_INTERESTED',
    SEND_FOUNDS = 'SEND_FOUNDS',
    RETAINER = 'RETAINER',
    FOLLOW_UP = 'FOLLOW_UP',
}

export const ACCEPTED = 'accepted';
export const CREATED = 'created';
export const WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL';
export const FOLLOW_UP = 'followUp';
export const NOT_INTERESTED = 'notInterested';
export const CONSULTATION = 'consultation';
export const RETAINER = 'retainer';
export const SEND_FOUNDS = 'sendFounds';

export enum HashRouteReferralStatus {
    ACCEPTED = 'accepted',
    CREATED = 'created',
    WAITING_FOR_APPROVAL = 'waitingForApproval',
    FOLLOW_UP = 'followUp',
    NOT_INTERESTED = 'notInterested',
    CONSULTATION = 'consultation',
    RETAINER = 'retainer',
    SEND_FOUNDS = 'sendFounds',
}

export const systemStatusToHashRouteMap = {
    [ReferralStatus.ACCEPTED]: HashRouteReferralStatus.ACCEPTED,
    [ReferralStatus.CREATED]: HashRouteReferralStatus.CREATED,
    [ReferralStatus.WAITING_FOR_APPROVAL]:
        HashRouteReferralStatus.WAITING_FOR_APPROVAL,
    [ReferralStatus.FOLLOW_UP]: HashRouteReferralStatus.FOLLOW_UP,
    [ReferralStatus.NO_INTERESTED]: HashRouteReferralStatus.NOT_INTERESTED,
    [ReferralStatus.CONSULTATION_SCHEDULED]:
        HashRouteReferralStatus.CONSULTATION,
    [ReferralStatus.RETAINER]: HashRouteReferralStatus.RETAINER,
    [ReferralStatus.SEND_FOUNDS]: HashRouteReferralStatus.SEND_FOUNDS,
};

export const hashRouteToSystemStatusMap = {
    [HashRouteReferralStatus.ACCEPTED]: ReferralStatus.ACCEPTED,
    [HashRouteReferralStatus.CREATED]: ReferralStatus.CREATED,
    [HashRouteReferralStatus.WAITING_FOR_APPROVAL]:
        ReferralStatus.WAITING_FOR_APPROVAL,
    [HashRouteReferralStatus.FOLLOW_UP]: ReferralStatus.FOLLOW_UP,
    [HashRouteReferralStatus.NOT_INTERESTED]: ReferralStatus.NO_INTERESTED,
    [HashRouteReferralStatus.CONSULTATION]:
        ReferralStatus.CONSULTATION_SCHEDULED,
    [HashRouteReferralStatus.RETAINER]: ReferralStatus.RETAINER,
    [HashRouteReferralStatus.SEND_FOUNDS]: [ReferralStatus.SEND_FOUNDS],
};

export const systemCommentToLogReferral = {
    [ReferralStatus.ACCEPTED]: 'The referral was accepted.',
    [ReferralStatus.CREATED]: 'The referral was created.',
    [ReferralStatus.FOLLOW_UP]: '#clientName needs additional consultation.',
    [ReferralStatus.NO_INTERESTED]: 'The client is not interested in services.',
    [ReferralStatus.CONSULTATION_SCHEDULED]:
        'Consultation with the #clientName was scheduled for the time #appointedAt.',
    [ReferralStatus.RETAINER]: '#clientName case was closed successfully.',
    [ReferralStatus.WAITING_FOR_APPROVAL]: 'The referral waiting for approval',
};

export const myCasesStatusMap = {
    [ReferralStatus.ACCEPTED]: 'Accepted',
    [ReferralStatus.CREATED]: 'Created',
    [ReferralStatus.WAITING_FOR_APPROVAL]: 'Waiting for approval',
    [ReferralStatus.FOLLOW_UP]: 'Follow Up',
    [ReferralStatus.NO_INTERESTED]: 'Not Interested',
    [ReferralStatus.CONSULTATION_SCHEDULED]: 'Consultation scheduled',
    [ReferralStatus.RETAINER]: 'Retainer',
    [ReferralStatus.SEND_FOUNDS]: 'n/a',
};

export const platforms = {
    WEB: 'web',
    MOBILE: 'mobile',
};

export default {
    navigation,
    userNavigation,
};
