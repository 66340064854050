import { store } from 'src/store';
import { useContext, useState, useEffect, useRef } from 'react';
import { AuthContext } from 'src/contexts/authContext';

import { NotificationTypeEnum } from 'src/components/common/snackbar/GeneralSnackbar';
import { addNewMessages } from 'src/store/slices/notificationsSlice';
import { LOGIN_PAGE_URL } from 'src/constants/links';
import { AuthStatus } from 'src/contexts/authContext';

const FORBIDDEN = 403;
const USER_DOES_NOT_HAVE_KYC = 'User does not have a KYC';
const KYC_PROFILE_NOT_FOUND = 'Resource "Kyc Profile" not found';

const useServerErrorHandler = () => {
    const prevStatus = useRef<AuthStatus>();
    const { authStatus } = useContext(AuthContext);
    const [error, setError] = useState<any>('');

    useEffect(() => {
        if (prevStatus.current !== authStatus) {
            prevStatus.current = authStatus;
            setError('');
        } else {
            serverErrorHandler(error, authStatus);
        }
    }, [error, authStatus]);

    return [setError];
};

export default useServerErrorHandler;

const serverErrorHandler = (err: any, authStatus: AuthStatus) => {
    console.error(err);
    if (!err) return;
    let redirectTo = undefined;
    let errMessage = getErrorMessage(err);
    let errStatusCode = err?.response
        ? err.response?.data.statusCode
        : err.statusCode;

    if (
        errMessage === USER_DOES_NOT_HAVE_KYC ||
        errMessage === KYC_PROFILE_NOT_FOUND
    ) {
        return;
    }

    if (errMessage === 'Network request failed') {
        errMessage = 'Network Error, please check your connection';
    }

    if (errMessage === 'Failed to fetch') {
        errMessage = 'Network Error, please check your connection';
    }

    if (errStatusCode === FORBIDDEN && authStatus === AuthStatus.SignedOut) {
        return;
    }

    if (errStatusCode === FORBIDDEN && authStatus === AuthStatus.SignedIn) {
        redirectTo = LOGIN_PAGE_URL;
    }

    store.dispatch(
        addNewMessages([
            {
                header: 'Error message',
                description: errMessage,
                type: NotificationTypeEnum.ERROR,
                redirectTo,
            },
        ]),
    );
};

const getErrorMessage = (err: any) => {
    let errorMessage = 'Unknown error';

    if (err.message) {
        errorMessage = err.message;
    }

    if (err?.data) {
        errorMessage = err.data.message;
    }

    if (err?.response) {
        errorMessage = err.response?.data.message;
    }

    if (errorMessage.includes('phone_number')) {
        errorMessage = errorMessage.replace('_', ' ');
    }

    return errorMessage;
};
