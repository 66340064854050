import { WEB_BUILD_ENV } from 'src/libs/config';

const AUTH = !WEB_BUILD_ENV ? '/auth' : '';
const STATIC = !WEB_BUILD_ENV ? '/static' : '';

export const ROOT_PAGE_URL = '/';

export const PRIVACY_POLICY_PAGE_URL = `${STATIC}/privacy-policy`;
export const TERMS_AND_CONDITIONS_PAGE_URL = `${STATIC}/terms-and-conditions`;

export const CONFIRM_EMAIL_PAGE_URL = `${AUTH}/confirm-email`;
export const FORGOT_PASSWORD_PAGE_URL = `${AUTH}/forgot-password`;
export const LOGIN_PAGE_URL = `${AUTH}/login`;
export const RECOVERY_PASSWORD_PAGE_URL = '/recovery-password';
export const SIGNUP_PAGE_URL = `${AUTH}/signup`;
export const VERIFY_EMAIL_PAGE_URL = `${AUTH}/verify-email`;
export const WELCOME_PAGE_URL = `${AUTH}/welcome`;
export const KYC_PAGE_URL = '/kyc';

export const MY_REFERRALS_PAGE_URL = '/myReferrals';

/**
 * @TODO: write a better description of what this section is about
 * @Dmitry
 */
////////__H_A_S_H_E_S__
export const SEND_REFERRAL_HASH = 'sendReferral';
