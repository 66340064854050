import {
    ClipboardCopyIcon,
    CurrencyDollarIcon,
    ClipboardListIcon,
    PlusCircleIcon,
    ArchiveIcon,
    TemplateIcon,
    BookOpenIcon,
} from '@heroicons/react/outline';
import { CogIcon } from '@heroicons/react/solid';

export const navigation = [
    {
        nameNav: 'SendReferral',
        name: 'Send a Referral',
        href: '#sendReferral',
        icon: PlusCircleIcon,
        current: true,
    },
    {
        nameNav: 'Dashboard',
        name: 'Dashboard',
        href: 'dashboard',
        icon: TemplateIcon,
        current: false,
    },
    {
        nameNav: 'GetReferrals',
        name: 'Get a Referrals',
        href: 'getReferrals',
        icon: ClipboardListIcon,
        current: false,
    },
    {
        nameNav: 'MyCases',
        name: 'My Cases',
        href: 'myCases',
        icon: ClipboardCopyIcon,
        current: false,
    },
    {
        nameNav: 'MyReferrals',
        name: 'My Referrals',
        href: 'myReferrals',
        icon: ArchiveIcon,
        current: false,
    },
    {
        nameNav: 'BalanceRewards',
        name: 'Balance & Rewards',
        href: 'balance',
        icon: CurrencyDollarIcon,
        current: false,
    },
    {
        nameNav: 'Settings',
        name: 'Settings',
        href: 'settings',
        icon: CogIcon,
        current: false,
    },
    // {
    //     nameNav: 'Support',
    //     name: 'Support',
    //     href: 'support',
    //     icon: BookOpenIcon,
    //     current: false,
    // },
];

export const adminNavigation = [
    {
        nameNav: 'InviteLawyers',
        name: 'Invite Lawyers',
        href: 'addInvite',
        icon: PlusCircleIcon,
        current: true,
        admin: true,
    },
    {
        nameNav: 'Deposit Funds Example',
        name: 'Deposit Funds',
        href: 'balance',
        icon: CurrencyDollarIcon,
        current: false,
    },
];

export const userNavigation = [
    { name: 'Your Profile', href: '/settings/#account' },
    // { name: 'Settings', href: '#' },
];
