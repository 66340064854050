export enum ProfileKycStatus {
    UNVERIFIED = 'UNVERIFIED',
    ON_VERIFICATION = 'ON_VERIFICATION',
    VERIFIED = 'VERIFIED',
    FAILED_VERIFICATION = 'FAILED_VERIFICATION',
}

export const areaOfLawList: Array<TSelectList> = [
    { label: 'FAMILY', value: 'FAMILY' },
    { label: 'IMMIGRATION', value: 'IMMIGRATION' },
    { label: 'CORPORATE', value: 'CORPORATE' },
    { label: 'LITIGATION', value: 'LITIGATION' },
    { label: 'EMPLOYMENT', value: 'EMPLOYMENT' },
    { label: 'INSURANCE', value: 'INSURANCE' },
    { label: 'HUMAN RIGHTS', value: 'HUMAN_RIGHTS' },
    { label: 'REAL ESTATE', value: 'REAL_ESTATE' },
];

export type TSelectList = {
    id?: number;
    label: string;
    value: string;
};

export type TStateAndCountryList = {
    countryId?: string;
    label: string;
    value: string;
};

export const countries: Array<TSelectList> = [
    { label: 'United States', value: 'US' },
    { label: 'Canada', value: 'CA' },
];

export const statesAndProvinces: Array<TStateAndCountryList> = [
    { label: 'Alabama', value: 'Alabama', countryId: 'US' },
    { label: 'Alaska', value: 'Alaska', countryId: 'US' },
    { label: 'Arizona', value: 'Arizona', countryId: 'US' },
    { label: 'Arkansas', value: 'Arkansas', countryId: 'US' },
    { label: 'California', value: 'California', countryId: 'US' },
    { label: 'Colorado', value: 'Colorado', countryId: 'US' },
    { label: 'Connecticut', value: 'Connecticut', countryId: 'US' },
    { label: 'Delaware', value: 'Delaware', countryId: 'US' },
    { label: 'Florida', value: 'Florida', countryId: 'US' },
    { label: 'Georgia', value: 'Georgia', countryId: 'US' },
    { label: 'Hawaii', value: 'Hawaii', countryId: 'US' },
    { label: 'Idaho', value: 'Idaho', countryId: 'US' },
    { label: 'Illinois', value: 'Illinois', countryId: 'US' },
    { label: 'Indiana', value: 'Indiana', countryId: 'US' },
    { label: 'Iowa', value: 'Iowa', countryId: 'US' },
    { label: 'Kansas', value: 'Kansas', countryId: 'US' },
    { label: 'Kentucky', value: 'Kentucky', countryId: 'US' },
    { label: 'Louisiana', value: 'Louisiana', countryId: 'US' },
    { label: 'Maine', value: 'Maine', countryId: 'US' },
    { label: 'Maryland', value: 'Maryland', countryId: 'US' },
    { label: 'Massachusetts', value: 'Massachusetts', countryId: 'US' },
    { label: 'Michigan', value: 'Michigan', countryId: 'US' },
    { label: 'Minnesota', value: 'Minnesota', countryId: 'US' },
    { label: 'Mississippi', value: 'Mississippi', countryId: 'US' },
    { label: 'Missouri', value: 'Missouri', countryId: 'US' },
    { label: 'Montana', value: 'Montana', countryId: 'US' },
    { label: 'Nebraska', value: 'Nebraska', countryId: 'US' },
    { label: 'Nevada', value: 'Nevada', countryId: 'US' },
    { label: 'New Hampshire', value: 'New Hampshire', countryId: 'US' },
    { label: 'New Jersey', value: 'New Jersey', countryId: 'US' },
    { label: 'New Mexico', value: 'New Mexico', countryId: 'US' },
    { label: 'New York', value: 'New York', countryId: 'US' },
    { label: 'North Carolina', value: 'North Carolina', countryId: 'US' },
    { label: 'North Dakota', value: 'North Dakota', countryId: 'US' },
    { label: 'Ohio', value: 'Ohio', countryId: 'US' },
    { label: 'Oklahoma', value: 'Oklahoma', countryId: 'US' },
    { label: 'Oregon', value: 'Oregon', countryId: 'US' },
    { label: 'Pennsylvania', value: 'Pennsylvania', countryId: 'US' },
    { label: 'Rhode Island', value: 'Rhode Island', countryId: 'US' },
    { label: 'South Carolina', value: 'South Carolina', countryId: 'US' },
    { label: 'South Dakota', value: 'South Dakota', countryId: 'US' },
    { label: 'Tennessee', value: 'Tennessee', countryId: 'US' },
    { label: 'Texas', value: 'Texas', countryId: 'US' },
    { label: 'Utah', value: 'Utah', countryId: 'US' },
    { label: 'Vermont', value: 'Vermont', countryId: 'US' },
    { label: 'Virginia', value: 'Virginia', countryId: 'US' },
    { label: 'Washington', value: 'Washington', countryId: 'US' },
    { label: 'West Virginia', value: 'West Virginia', countryId: 'US' },
    { label: 'Wisconsin', value: 'Wisconsin', countryId: 'US' },
    { label: 'Alberta', value: 'Alberta', countryId: 'CA' },
    { label: 'British Columbia', value: 'British Columbia', countryId: 'CA' },
    { label: 'Manitoba', value: 'Manitoba', countryId: 'CA' },
    { label: 'New Brunswick', value: 'New Brunswick', countryId: 'CA' },
    {
        label: 'Newfoundland and Labrador',
        value: 'Newfoundland and Labrador',
        countryId: 'CA',
    },
    { label: 'Nova Scotia', value: 'Nova Scotia', countryId: 'CA' },
    { label: 'Ontario', value: 'Ontario', countryId: 'CA' },
    {
        label: 'Prince Edward Island',
        value: 'Prince Edward Island',
        countryId: 'CA',
    },
    { label: 'Quebec', value: 'Quebec', countryId: 'CA' },
    { label: 'Saskatchewan', value: 'Saskatchewan', countryId: 'CA' },
    {
        label: 'Northwest Territories',
        value: ' Northwest Territories',
        countryId: 'CA',
    },
    { label: 'Nunavut', value: 'Nunavut', countryId: 'CA' },
    { label: 'Yukon', value: 'Yukon', countryId: 'CA' },
];
