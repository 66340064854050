import { Dialog } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import { CSSProperties, ReactNode } from 'react';
import SideModalHeader from './SideModalHeader';

export type ModalProps = {
    open: boolean;
    onClose: () => any;
    title?: ReactNode;
    children?: ReactNode;
    icon?: ReactNode;
    headerLabel?: string;
    OverlayStyle?: CSSProperties;
    buttons?: [
        {
            component: ReactNode;
        },
    ];
};

const OverlayStyle: CSSProperties = {
    position: 'fixed',
    opacity: 0.3,
    background: 'black',
};

const ModalStyle: CSSProperties = {
    padding: '30px 0 30px 30px',
    maxWidth: '710px',
    position: 'relative',
};

const SideModal = (props: ModalProps) => (
    <Dialog
        className="fixed inset-0 z-50 flex md:justify-end"
        open={props.open}
        onClose={props.onClose}
    >
        <Dialog.Overlay
            className="hidden md:block md:fixed inset-0 bg-themeBlack opacity-30"
            style={{ ...OverlayStyle, ...props.OverlayStyle }}
        />

        <div
            className="hidden md:block relative p-5 z-90 cursor-pointer"
            onClick={props.onClose}
        >
            <XIcon className="w-5 h-5 text-white" />
        </div>
        <div
            className="relative bg-white lg:w-[710px] w-full"
            style={
                !!props.headerLabel
                    ? { ...ModalStyle, paddingTop: 0 }
                    : ModalStyle
            }
        >
            {props.headerLabel ? (
                <SideModalHeader
                    label={props.headerLabel}
                    onClose={props.onClose}
                />
            ) : null}
            <div className="overflow-y-auto h-full pr-[30px] pt-[30px]">
                {props.children}
                <br />
                {(props.buttons || []).map((btn) => btn.component)}
            </div>
        </div>
    </Dialog>
);

export default SideModal;
