import {
    AuthenticationDetails,
    CognitoUser,
    CognitoUserAttribute,
    CognitoUserPool,
    CognitoUserSession,
    ISignUpResult,
} from 'amazon-cognito-identity-js';
import axios from 'axios';
import { NEXT_PUBLIC_API_ENDPOINT, poolData } from './config';
import { sendVerificationEmail } from './http-api';
import { setUserData } from './localStorage';
export type UserSessionData = {
    accessToken: string;
    idToken: string;
    refreshToken: string;
    // idToken.payload.exp
    expirationTimestamp: number;
    userData: {
        [key: string]: string | number | boolean | any[];
        // idToken.payload.email_verified
        emailVerified: boolean;
        // idToken.payload.email
        email: string;
        // idToken.payload.sub
        id: string;
    };
};

const deSerializeCognitoUserSession = (
    session: CognitoUserSession,
): UserSessionData => {
    const result: UserSessionData = {
        // @ts-ignore  token String are not listed in typings
        accessToken: session.accessToken.jwtToken,
        // @ts-ignore
        idToken: session.idToken.jwtToken,
        // @ts-ignore
        refreshToken: session.refreshToken.jwtToken,
        expirationTimestamp: session.getIdToken().payload.exp,
        userData: {
            ...session.getIdToken().payload,
            email: session.getIdToken().payload.email,
            emailVerified: session.getIdToken().payload.email_verified,
            id: session.getIdToken().payload.sub,
        },
    };

    return result;
};

function generateCognitoUser(emailOrEmail: string) {
    const userData = {
        Username: emailOrEmail,
        Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    return cognitoUser;
}

// @TODO: Generate strong one
async function generateStrongPassword() {
    var result = '';
    var characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < 100; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength),
        );
    }
    return result + 'a!1A';
}

const userPool: CognitoUserPool = new CognitoUserPool(poolData);

// SESSION AND USER DATA MANAGEMENT
/**
 * Get current logged User
 */
export function getCurrentUser() {
    const currentUser: CognitoUser | null = userPool.getCurrentUser();
    return currentUser;
}

export async function getSession(): Promise<UserSessionData | null> {
    let currentUser = getCurrentUser();
    if (!getCurrentUser) {
        currentUser = userPool.getCurrentUser();
    }

    return new Promise(function (resolve, reject) {
        if (currentUser === null) return resolve(null);
        currentUser.getSession(function (
            err: any,
            session: CognitoUserSession,
        ) {
            if (err) {
                reject(err);
            } else {
                return resolve(deSerializeCognitoUserSession(session));
            }
        });
    });
}

export async function getAttributes() {
    return new Promise(function (resolve, reject) {
        getCurrentUser()?.getUserAttributes(function (
            err: any,
            attributes: any,
        ) {
            if (err) {
                reject(err);
            } else {
                resolve(attributes);
            }
        });
    }).catch((err) => {
        throw err;
    });
}

export async function setAttribute(attribute: any) {
    return new Promise(function (resolve, reject) {
        const attributeList = [];
        const res = new CognitoUserAttribute(attribute);
        attributeList.push(res);

        getCurrentUser()?.updateAttributes(
            attributeList,
            (err: any, res: any) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            },
        );
    }).catch((err) => {
        throw err;
    });
}

export function signOut() {
    const currentUser = getCurrentUser();
    if (currentUser) {
        currentUser.signOut();
    }
}

// SIGN UP / LOGIN WITH EMAIL
/**
 * Creates account with
 * @param email Email
 * @param password Password
 * @returns
 */
export async function signUpUserWithEmail(
    email: string,
    password: string,
): Promise<ISignUpResult> {
    return new Promise(function (resolve, reject) {
        userPool.signUp(email, password, [], [], function (err, res) {
            if (err) {
                reject(err);
            } else {
                resolve(res as ISignUpResult);
                sendVerificationEmail(email).catch(console.error);
            }
        });
    });
}
export async function logInWithEmail(
    email: string,
    password: string,
): Promise<UserSessionData> {
    return new Promise(function (resolve, reject) {
        const authenticationData = {
            Username: email,
            Password: password,
        };
        const authenticationDetails = new AuthenticationDetails(
            authenticationData,
        );

        const currentUser = generateCognitoUser(email);

        currentUser.authenticateUser(authenticationDetails, {
            onSuccess: function (res: CognitoUserSession) {
                const session = deSerializeCognitoUserSession(res);
                resolve(session);
            },
            onFailure: function (err: any) {
                reject(err);
            },
        });
    });
}

// SIGN UP WITH PHONE
async function signUpCreateAccountByPhone(phone: string) {
    return new Promise(async (resolve, reject) => {
        const generatedStrongPassword = await generateStrongPassword();
        userPool.signUp(
            phone,
            generatedStrongPassword,
            /**
             * CAUTION: this is a hack, default example for aws passwordless auth requires name as an attribute...
             * So to save some time and test it quickly I just added it, a future final version of userpool will not require it
             * In that case please delete.
             */
            [
                new CognitoUserAttribute({
                    Name: 'name',
                    Value: generatedStrongPassword,
                }),
            ],
            [],
            function (err, res) {
                if (err) {
                    reject(err);
                } else {
                    resolve(res as ISignUpResult);
                }
            },
        );
    });
}
/**
 * Creates an user by phone, generating a default password
 * @param phone
 */
export async function signUpSendCodeByPhone(phone: string) {
    await signUpCreateAccountByPhone(phone);
    await logInSendCodeByPhone(phone);
}

/**
 * After get the code, use this method to create accound by phone
 * @param phoneNumber User phone number
 * @param code Verify code
 * @returns
 */

export async function resendCodeToPhone(phone: string): Promise<any> {
    return logInSendCodeByPhone(phone);
}
let user: CognitoUser | null = null;

// export async function welcome(email: string): Promise<UserSessionData> {
//   return new Promise(function (resolve, reject) {
//     const authenticationData = {
//       Username: email,
//     };
//     const authenticationDetails = new AuthenticationDetails(authenticationData);

//     const currentUser = getCurrentUser(email);

//     currentUser.authenticateUser(authenticationDetails, {
//       onSuccess: function (res: CognitoUserSession) {
//         const session = deSerializeCognitoUserSession(res);
//         setUserData(session);
//         resolve(session);
//       },
//       onFailure: function (err: any) {
//         reject(err);
//       },
//     });
//   });
// }

export async function logInSendCodeByPhone(phone: string) {
    user = generateCognitoUser(phone);
    user.setAuthenticationFlowType('CUSTOM_AUTH');
    const authenticationData = { Username: phone };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    return new Promise((resolve, reject) => {
        (user as CognitoUser).initiateAuth(authenticationDetails, {
            onSuccess(result) {
                // console.log('successful CODE LOGIN');
            },
            onFailure(err) {
                // console.log('ERROR CODE LOGIN');

                return reject(err);
            },
            customChallenge(challengeParameters) {
                return resolve(challengeParameters);

                // // console.log('CUSTOM CHALLENGE HERE',challengeParameters)
                // onCodeSent()
            },
        });
    });
}

export async function verifyCodeSentToPhone(phone: string, code: string) {
    // console.log('verifyCodeSentToPhone', user);
    const resultCognito = await new Promise(function (resolve, reject) {
        (user as CognitoUser).sendCustomChallengeAnswer(code, {
            onSuccess: () => {
                return resolve(null);
            },
            onFailure: (err) => {
                return reject(err);
            },
            customChallenge(challengeParameters) {
                return resolve(challengeParameters);

                // // console.log('CUSTOM CHALLENGE HERE',challengeParameters)
                // onCodeSent()
            },
        });
    });
    if (resultCognito) {
        throw new Error('Invalid code entered. Please try again');
    }
}

// PASSWORD MANAGEMENT

export async function forgotPasswordSendRetrieveEmail(email: string) {
    return new Promise(function (resolve, reject) {
        const cognitoUser = generateCognitoUser(email);

        if (!cognitoUser) {
            reject(`could not find ${email}`);
            return;
        }

        cognitoUser.forgotPassword({
            onSuccess: function (res) {
                resolve(res);
            },
            onFailure: function (err) {
                reject(err);
            },
        });
    }).catch((err) => {
        throw err;
    });
}

export async function forgotPasswordSetNewPassword(
    email: string,
    code: string,
    password: string,
) {
    return new Promise(function (resolve, reject) {
        const cognitoUser = generateCognitoUser(email);

        if (!cognitoUser) {
            reject(`could not find ${email}`);
            return;
        }

        cognitoUser.confirmPassword(code, password, {
            onSuccess: function () {
                resolve('password updated');
            },
            onFailure: function (err) {
                reject(err);
            },
        });
    });
}

export async function changePassword(oldPassword: string, newPassword: string) {
    return new Promise(function (resolve, reject) {
        const currentUser = getCurrentUser();
        currentUser?.getSession(() => {}); //HACK!!! This function rehydrate session. The session param is mandatory for changing password
        currentUser?.changePassword(
            oldPassword,
            newPassword,
            function (err: any, res: any) {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            },
        );
    });
}
