export const poolData = {
    UserPoolId: process.env.NEXT_PUBLIC_APP_USERPOOL_ID || '',
    ClientId: process.env.NEXT_PUBLIC_APP_CLIENT_ID || '',
};

export const NEXT_PUBLIC_API_ENDPOINT = process.env.NEXT_PUBLIC_API_ENDPOINT;

export const PROD_BUILD_ENV =
    process.env.NEXT_PUBLIC_BUILD_ENV === 'WEB_PROD' ||
    process.env.NEXT_PUBLIC_BUILD_ENV === 'MOBILE_PROD';

export const WEB_BUILD_ENV =
    process.env.NEXT_PUBLIC_BUILD_ENV === 'WEB_PROD' ||
    process.env.NEXT_PUBLIC_BUILD_ENV === 'WEB_DEV';
